




































































































































import { Vue, Component } from "vue-property-decorator";
import { User } from "@/types";
import axios, { AxiosRequestHeaders, AxiosError, AxiosResponse } from "axios";

@Component({
  metaInfo() {
    return {
      title: "Düzenle / Kullanıcılar / Admin"
    };
  }
})
export default class AdminUserEditView extends Vue {
  user = {} as User;

  form = {
    firstName: "",
    lastName: "",
    password: "",
    passwordConfirm: "",
    email: "",
    phone: "",
    tckn: "",
    gender: "",
    eduLevel: "",
    city: "",
    address: "",
    profession: ""
  };

  submitDisabled = false;
  
  gender = ["Kadın", "Erkek"];

  eduLevels = ["İlköğretim", "Lise", "Ön Lisans", "Lisans", "Yüksek Lisans", "Doktora"];

  get firstNameRules(): unknown[] {
    return [(v: string) => !!v || "Lütfen adınızı yazınız"];
  }

  get lastNameRules(): unknown[] {
    return [(v: string) => !!v || "Lütfen soyadınızı yazınız"];
  }

  get emailRules(): unknown[] {
    return [
      (v: string) => !!v || "Lütfen e-postanızı yazınız",
      (v: string) => /.+@.+\..+/.test(v) || "Geçersiz e-posta adresi"
    ];
  }

  validate(): boolean {
    const form = this.$refs.form as HTMLFormElement;
    return form.validate();
  }

  reset(): void {
    const form = this.$refs.form as HTMLFormElement;
    form.reset();
  }

  resetValidation(): void {
    const form = this.$refs.form as HTMLFormElement;
    form.resetValidation();
  }

  async update() {
    if (this.validate()) {
      try {
        this.submitDisabled = true;

        const authHeader = this.$store.getters["auth/requestAuthHeader"];

        const res = await axios.post(
          `/api/user/update/${this.user.id}`,
          {
            Username: this.form.email,
            Email: this.form.email,
            FirstName: this.form.firstName,
            LastName: this.form.lastName,
            Phone: this.form.phone,
            Tckn: this.form.tckn,
            Gender: this.form.gender,
            EduLevel: this.form.eduLevel,
            Profession: this.form.profession,
            Address: this.form.address
          }, 
          {
            headers: authHeader  
          }
        );

        this.$notify({ type: "success", text: "Kullanıcı başarıyla güncellendi." });
      } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
          this.$notify({ type: "error", text: e.response?.data.message});
        } else {
          this.$notify({ type: "error", text: "Beklenmedik bir hata nedeniyle kullanıcı güncellenemedi." });
        }
      } finally {
        this.submitDisabled = false;
      }
    }
  }

  async getUser() {
    try {
      const userId = this.$route.params.userId;
      const authHeader = this.$store.getters["auth/requestAuthHeader"];

      // Get user
      var res = await axios.get(`/api/user/${userId}`, {
        headers: authHeader
      });

      this.user = res.data;
      this.form = res.data;

      if (this.user.dateCreated) this.user.dateCreated = new Date(this.user.dateCreated);

      // this.$notify({
      //   type: "success",
      //   title: "İşlem Tamam",
      //   text: "Kullanıcı bilgileri alındı."
      // });
    } catch (e) {
      this.$notify({
        type: "error",
        title: "Hata!",
        text: `Kullanıcı bilgileri alınamadı.`
      });

      throw e;
    }
  }

  async mounted() {
    await this.getUser();
  }
}
